import { http } from '@/lib/http'
import { ChartValueItemTypes, DashboardChartListTypes } from '#/DashboardTypes'

export default {
  /**
   * 获取Treasury数据
   * @param entityId 主体ID
   */
  getTreasuryData: (entityId: string) => {
    return http.request<ResponseData<DashboardChartListTypes>>('get', `/entity/${entityId}/dashboard/treasury/chart`)
  },
  /**
   * 获取Reports数据
   * @param entityId 主体ID
   * @param params 请求参数
   * @property { string } params.period 时间周期
   * @property { string[] } params.dateRange 时间范围
   */
  getReportsData: (entityId: string, params: any) => {
    return http.request<ResponseData<DashboardChartListTypes>>('get', `/entity/${entityId}/dashboard/report/chart`, {
      params
    })
  },
  /**
   * 添加Chart数据
   * @param entityId 主体ID
   * @param { object} data 新增Chart数据
   * @property { string } data.name 图表类型
   * @property { string } data.sourceType 图表名称
   * @property { string } data.metric 图表配置
   * @property { string } data.targetType 图表描述
   * @property { string } data.showType 图表类型
   * @property { object } data.config 图表数据
   * @property { string[] } data.config.currencies 图表副标题
   * @property { string } data.config.timeRange 图表标题 Treasury
   * @property { string } data.config.viewTimeRange 图表单位 Reports
   */
  addChartData: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/chart`, { data })
  },
  /**
   * 编辑Chart数据
   * @param entityId 主体ID
   * @param chartId ChartID
   * @param { object} data 新增Chart数据
   * @property { string } data.name 图表类型
   * @property { string } data.sourceType 图表名称
   * @property { string } data.metric 图表配置
   * @property { string } data.targetType 图表描述
   * @property { string } data.showType 图表类型
   * @property { object } data.config 图表数据
   * @property { string[] } data.config.currencies 图表副标题
   * @property { string } data.config.timeRange 图表标题 Treasury
   * @property { string } data.config.viewTimeRange 图表单位 Reports
   */
  editChartData: (entityId: string, chartId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/chart/${chartId}`, { data })
  },
  /**
   * 删除Chart数据
   * @param { string } entityId 主体ID
   * @param { string } chartId ChartID
   */
  deleteChartData: (entityId: string, chartId: string) => {
    return http.request('delete', `/entity/${entityId}/chart/${chartId}`)
  },
  /**
   * 排序Chart数据
   * @param entityId 主体ID
   * @param { object } data 排序数据
   * @property { string[] } data.chartIds 图表ID数组
   * @property { string } data.sourceType 排序类型  TREASURY_BALANCE | REPORT
   */

  sortChartDataList: (entityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/chart/order`, { data })
  },
  /**
   * 预览Treasury Chart数据
   * @param entityId 主体ID
   * @param {object } data 预览数据
   * @property { string } data.targetType 图表展示类型 TREND | DISTRIBUTION
   * @property { object } data.config 图表配置
   * @property { string } data.config.timeRange 时间范围
   * @property { string[] } data.config.currencyList 图表币种信息
   */
  previewTreasuryChartData: (entityId: string, data: object) => {
    return http.request<ResponseData<ChartValueItemTypes[]>>(
      'post',
      `/entity/${entityId}/dashboard/treasury/chart/preview`,
      { data }
    )
  },
  /**
   * 预览Reports Chart数据
   * @param entityId 主体ID
   * @param {object } data 预览数据
   * @property { string } data.period 时间周期
   * @property { string } data.timeRange 时间范围
   * @property { object } data.config 图表配置
   * @property { string[] } data.config.currencyList 图表币种信息
   * @property { string } data.config.viewTimeRange 预览时间范围
   */
  previewReportsChartData: (entityId: string, data: object) => {
    return http.request<ResponseData<ChartValueItemTypes[]>>(
      'post',
      `/entity/${entityId}/dashboard/report/chart/preview`,
      { data }
    )
  }
}
